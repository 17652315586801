import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/app/apps/dop-employee-portal/components/Providers/AppProvider.tsx");
;
import(/* webpackMode: "eager" */ "/tmp/app/apps/dop-employee-portal/mocks/index.ts");
;
import(/* webpackMode: "eager" */ "/tmp/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.9_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.78.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/tmp/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.9_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.78.0/node_modules/next/font/local/target.css?{\"path\":\"../../packages/fonts/nextjs.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./Pretendard-1.3.9/web/variable/woff2/PretendardVariable.woff2\",\"display\":\"swap\",\"weight\":\"45 920\",\"variable\":\"--font-pretendard-variable\"}],\"variableName\":\"PretendardVariable\"}");
;
import(/* webpackMode: "eager" */ "/tmp/app/packages/tailwindcss/tailwindcss.css");
;
import(/* webpackMode: "eager" */ "/tmp/app/design/dist/common.css");
;
import(/* webpackMode: "eager" */ "/tmp/app/design/dist/foundation/accordion.css");
;
import(/* webpackMode: "eager" */ "/tmp/app/design/dist/foundation/avatar.css");
;
import(/* webpackMode: "eager" */ "/tmp/app/design/dist/foundation/badge.css");
;
import(/* webpackMode: "eager" */ "/tmp/app/design/dist/foundation/button.css");
;
import(/* webpackMode: "eager" */ "/tmp/app/design/dist/foundation/card.css");
;
import(/* webpackMode: "eager" */ "/tmp/app/design/dist/foundation/checkbox.css");
;
import(/* webpackMode: "eager" */ "/tmp/app/design/dist/foundation/divider.css");
;
import(/* webpackMode: "eager" */ "/tmp/app/design/dist/foundation/dragndrop.css");
;
import(/* webpackMode: "eager" */ "/tmp/app/design/dist/foundation/icon.css");
;
import(/* webpackMode: "eager" */ "/tmp/app/design/dist/foundation/input.css");
;
import(/* webpackMode: "eager" */ "/tmp/app/design/dist/foundation/pagination.css");
;
import(/* webpackMode: "eager" */ "/tmp/app/design/dist/foundation/panel.css");
;
import(/* webpackMode: "eager" */ "/tmp/app/design/dist/foundation/popover.css");
;
import(/* webpackMode: "eager" */ "/tmp/app/design/dist/foundation/progressbar.css");
;
import(/* webpackMode: "eager" */ "/tmp/app/design/dist/foundation/radio.css");
;
import(/* webpackMode: "eager" */ "/tmp/app/design/dist/foundation/search.css");
;
import(/* webpackMode: "eager" */ "/tmp/app/design/dist/foundation/select.css");
;
import(/* webpackMode: "eager" */ "/tmp/app/design/dist/foundation/tab.css");
;
import(/* webpackMode: "eager" */ "/tmp/app/design/dist/foundation/table.css");
;
import(/* webpackMode: "eager" */ "/tmp/app/design/dist/foundation/tag.css");
;
import(/* webpackMode: "eager" */ "/tmp/app/design/dist/foundation/textarea.css");
;
import(/* webpackMode: "eager" */ "/tmp/app/design/dist/foundation/toolbar.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/app/packages/ui-react/shared/lib/i18n/client/translations-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useTranslation"] */ "/tmp/app/packages/ui-react/shared/lib/i18n/client/use-translation.tsx");
