import { initTranslations } from '@dop-ui/react/shared/lib/i18n/client';

/**
 * 다국어 리소스 로더
 * @param locale 
 * @param namespace 
 * @returns 
 */
export function loadResource(locale: string, namespace: string) {
  // 서버가 준비될 때까지 임시로 로컬에 있는 다국어 파일을 로드함
  return import(`./locales/${locale}/${namespace}.js`);
}

/**
 * 다국어 리소스 가져오기 (서버용)
 * @param locale 
 * @param namespaces 
 * @returns 
 */
export async function getTranslations(locale: string, namespaces: string[]) {
  return await initTranslations({
    locale,
    namespaces,
    loadResource
  });
}
