'use client';

import { enableMocks } from '@dop-ui/react/shared/lib/mocks/browser';
import { handlers } from './handlers';

if (process.env.NEXT_PUBLIC_MSW_ENABLED === 'true') {
  enableMocks()
    .then((instance) => {
      instance.use(...handlers);
    })
    .catch(console.error);
}
