'use client';

import type { PropsWithChildren } from 'react';
import { loadResource } from '@/i18n';

import { NextAppProvider as ClientNextAppProvider } from '@dop-ui/react/app/providers';

export interface Props {
  locale: string;
}

export const CLINET_NAMESPACES = ['common', 'component'];

export default function AppProvider({
  children,
  locale,
}: PropsWithChildren<Props>) {
  return (
    <ClientNextAppProvider
      locale={locale}
      i18nResourceLoader={loadResource}
      i18nNs={CLINET_NAMESPACES}
    >
      {children}
    </ClientNextAppProvider>
  );
}
